/* Loader.css */


.loader-bar {
    height: 100%;
    background-color: #1A1A1A; /* Customize the color */
    transition: width 0.1s ease-in-out;
}

.loader-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 14px;
    color: #white; /* Match the bar color */
    font-weight: bold;
}


.loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #eee;
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    grid-gap: 30px;
}

.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid #1A1A1A;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    from {
        opacity: 1;
    }
    to {
        transform: rotate(360deg);
        opacity: 0;
    }

}

p {
    margin-top: 16px;
    font-size: 16px;
    color: #1A1A1A;
}
