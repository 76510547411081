#footer {
  background: #FADAC1;

  footer {
    padding: 40px 0;
    display: flex;
    align-items: center;
    .info_links {
      display: flex;
      grid-gap: 10px;
      li {
        position: relative;
        margin-right: 10px;
        &:after {
          content: '';
          position: absolute;
          right: -10px;
          color: red;
          width: 1px;
          height: 15px;
          top: 5px;
          background: black;
        }
        &:last-child {
          &:after {
            content: none;
          }
        }

        a {
          &:hover {
            text-decoration: underline;
          }
          &.active {
            all: unset;
            text-decoration: underline;
          }
        }
      }
    }
  }
  .copyrights {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 40px;
  }
}


// `sm` applies to x-small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  #footer {
    background: #FADAC1;

    footer {
      padding: 40px 0;
      flex-direction: column;
      .info_links {
        margin-bottom: 40px;
      }
    }
  }
}

// `md` applies to small devices (landscape phones, less than 768px)
@media (min-width: 767.98px) {}

// `lg` applies to medium devices (tablets, less than 992px)
@media (min-width: 991.98px) {}

// `xl` applies to large devices (desktops, less than 1200px)
@media (min-width: 1199.98px) {}

// `xxl` applies to x-large devices (large desktops, less than 1400px)
@media (max-width: 1399.98px) {}