.header {
    position: fixed;
    top: 0; /* Start hidden above the viewport */
    width: 100%;
    background-color: rgba(255, 255, 255, 0.8); /* Slight transparency */
    transition: top 0.6s ease, filter 0.6s ease, opacity 0.6s ease;
    z-index: 3;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(5px); /* Apply blur to background */
    opacity: 0; /* Start as fully transparent */
}

.header.visible {
    top: 0px;
    opacity: 1; /* Fully visible */
    filter: blur(0); /* No blur when visible */
}

.header.hidden {
    top: -200px; /* Adjust based on header height */
    opacity: 0; /* Fully transparent */
    filter: blur(10px); /* Increase blur when hidden */
}


.height {
    height: 1000px;
}
