.social-icons a {
  border: 1px solid #1A1A1A;
  background: #1A1A1A;
  color: white;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: ease-in-out 0.35s;
}
.social-icons a:hover, .social-icons a:focus {
  background: white;
  border: 1px solid #1A1A1A;
  color: black;
  transition: ease-in-out 0.35s;
}

