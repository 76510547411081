.social-icons {

    a {
      border: 1px solid #1A1A1A;
      background: #1A1A1A;
      color: white;
      width: 28px;
      height: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      transition: ease-in-out .35s;
      &:hover,
      &:focus {
        background: white;
        border: 1px solid #1A1A1A;
        color: black;
        transition: ease-in-out .35s;
      }
  }

}


// `sm` applies to x-small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {}

// `md` applies to small devices (landscape phones, less than 768px)
@media (min-width: 767.98px) {}

// `lg` applies to medium devices (tablets, less than 992px)
@media (min-width: 991.98px) {}

// `xl` applies to large devices (desktops, less than 1200px)
@media (min-width: 1199.98px) {}

// `xxl` applies to x-large devices (large desktops, less than 1400px)
@media (max-width: 1399.98px) {}