#footer {
  background: #FADAC1;
}
#footer footer {
  padding: 40px 0;
  display: flex;
  align-items: center;
}
#footer footer .info_links {
  display: flex;
  grid-gap: 10px;
}
#footer footer .info_links li {
  position: relative;
  margin-right: 10px;
}
#footer footer .info_links li:after {
  content: "";
  position: absolute;
  right: -10px;
  color: red;
  width: 1px;
  height: 15px;
  top: 5px;
  background: black;
}
#footer footer .info_links li:last-child:after {
  content: none;
}
#footer footer .info_links li a:hover {
  text-decoration: underline;
}
#footer footer .info_links li a.active {
  all: unset;
  text-decoration: underline;
}
#footer .copyrights {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 40px;
}

@media (max-width: 575.98px) {
  #footer {
    background: #FADAC1;
  }
  #footer footer {
    padding: 40px 0;
    flex-direction: column;
  }
  #footer footer .info_links {
    margin-bottom: 40px;
  }
}

