#features {
  padding: 80px 0;
}
#features .item {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #EEEEEE;
  border-radius: 15px;
  overflow: hidden;
  position: relative;
  transition: 500ms ease;
}
#features .item a {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
}
#features .item img {
  transition: 500ms ease;
  width: 100%;
  border-radius: 15px;
}
#features .item:hover, #features .item:hover {
  transition: 500ms ease;
}
#features .item:hover img, #features .item:hover img {
  transform: scale(1.1);
  transition: 500ms ease;
  border-radius: 15px;
}

