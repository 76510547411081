.active {
  color: blue; /* Change this to your desired active link style */
  font-weight: bold;
}

.menu {
  ul {
    li {

      a {
        height: 80px;
        padding: 0 30px;
        line-height: 80px;
        display: block;
        transition: ease-in-out .35s;

        &:hover,
        &:focus {
          background: #1A1A1A;
          color: white;
          transition: ease-in-out .35s;
        }

        &.active {
          background: #1A1A1A;
          color: white;
          transition: ease-in-out .35s;
        }
      }
      .dropdown {
        top: 80px;
      }
    }
  }
}

.product-list {
  li {
    border-bottom: 1px solid #eee;
    line-height: 26px;
    &:nth-last-child(-n + 2) {
    border-bottom: 0;
  }
    a {
      all: unset;
      padding: 10px 0 !important;
      margin: 0 !important;
      display: flex !important;
      justify-content: flex-start;
      align-items: center;
      grid-gap: 15px;
      line-height: 26px !important;
      height: inherit !important;
      color: rgba(26, 26, 26, 0.67) !important;
      img {
        border-radius: 8px;
        height: 40px;
        width: 40px;
        backface-visibility: hidden;
        transform: translateZ(0);
        transition: all .5s;
      }
      &:hover,
      &:focus {
        background: white !important;
        color: #1A1A1A !important;
        cursor: pointer;
        transition: ease-in-out .35s;
        img {
          transform: scale(1.1);
        }
      }
    }
  }
}

#sideBarMenu {
  max-width: 400px;
  width: 290px;
  position: relative;
  z-index: 1140;
  .menu {
    flex-direction: column;
    max-width: 400px;
    position: relative;
    z-index: 40;
    ul {
      display: flex;
      flex-direction: column;
      li {
        padding: 0;
        width: 100%;
        border-bottom: 1px solid #eee;
        line-height: 26px;
        &:last-child {
          border: 0;
        }
        a {
          padding: 10px 0 !important;
          margin: 0 !important;
          display: flex !important;
          justify-content: flex-start;
          align-items: center;
          grid-gap: 15px;
          line-height: 26px !important;
          height: inherit !important;
          color: rgba(26, 26, 26, 1) !important;
          background: transparent;
          width: 100%;
        }
        .dropdown {
          position: relative !important;
          top: 0;
          z-index: 140;
          border: 0;
          box-shadow: none;
        }
      }
    }
  }
}

#drawerMenu {
  background: transparent;
  .mega-menu {
    max-width: 400px;

    .product-list {
      display: flex;
      flex-direction: column;
      padding: 0;
      gap: 0;
      li {
        padding: 0;
        a {
          span {
            font-size: 14px;
          }
        }
      }
    }
  }
}

/*# sourceMappingURL=menu.css.map */
