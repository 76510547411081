#intro .intro h3 {
  padding-bottom: 30px;
  margin-bottom: 30px;
  position: relative;
}
#intro .intro h3:before {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2px;
  background: #FADAC1;
}
#intro .intro p {
  color: rgba(0, 0, 0, 0.6);
}

