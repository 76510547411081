#intro {

  .intro {
    h3 {
      padding-bottom: 30px;
      margin-bottom: 30px;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 2px;
        background: #FADAC1;
      }
    }
    p {
      color: rgba(0,0,0,0.6);
    }
  }
}

// `sm` applies to x-small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {}

// `md` applies to small devices (landscape phones, less than 768px)
@media (min-width: 767.98px) {}

// `lg` applies to medium devices (tablets, less than 992px)
@media (min-width: 991.98px) {}

// `xl` applies to large devices (desktops, less than 1200px)
@media (min-width: 1199.98px) {}

// `xxl` applies to x-large devices (large desktops, less than 1400px)
@media (max-width: 1399.98px) {}