.active {
  color: blue; /* Change this to your desired active link style */
  font-weight: bold;
}

.menu ul li a {
  height: 80px;
  padding: 0 30px;
  line-height: 80px;
  display: block;
  transition: ease-in-out 0.35s;
}
.menu ul li a:hover, .menu ul li a:focus {
  background: #1A1A1A;
  color: white;
  transition: ease-in-out 0.35s;
}
.menu ul li a.active {
  background: #1A1A1A;
  color: white;
  transition: ease-in-out 0.35s;
}
.menu ul li .dropdown {
  top: 80px;
}

.product-list li {
  border-bottom: 1px solid #eee;
  line-height: 26px;
}
.product-list li:nth-last-child(-n+2) {
  border-bottom: 0;
}
.product-list li a {
  all: unset;
  padding: 10px 0 !important;
  margin: 0 !important;
  display: flex !important;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 15px;
  line-height: 26px !important;
  height: inherit !important;
  color: rgba(26, 26, 26, 0.67) !important;
}
.product-list li a img {
  border-radius: 8px;
  height: 40px;
  width: 40px;
  backface-visibility: hidden;
  transform: translateZ(0);
  transition: all 0.5s;
}
.product-list li a:hover, .product-list li a:focus {
  background: white !important;
  color: #1A1A1A !important;
  cursor: pointer;
  transition: ease-in-out 0.35s;
}
.product-list li a:hover img, .product-list li a:focus img {
  transform: scale(1.1);
}

#sideBarMenu {
  max-width: 400px;
  width: 290px;
  position: relative;
  z-index: 1140;
}
#sideBarMenu .menu {
  flex-direction: column;
  max-width: 400px;
  position: relative;
  z-index: 40;
}
#sideBarMenu .menu ul {
  display: flex;
  flex-direction: column;
}
#sideBarMenu .menu ul li {
  padding: 0;
  width: 100%;
  border-bottom: 1px solid #eee;
  line-height: 26px;
}
#sideBarMenu .menu ul li:last-child {
  border: 0;
}
#sideBarMenu .menu ul li a {
  padding: 10px 0 !important;
  margin: 0 !important;
  display: flex !important;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 15px;
  line-height: 26px !important;
  height: inherit !important;
  color: rgb(26, 26, 26) !important;
  background: transparent;
  width: 100%;
}
#sideBarMenu .menu ul li .dropdown {
  position: relative !important;
  top: 0;
  z-index: 140;
  border: 0;
  box-shadow: none;
}

#drawerMenu {
  background: transparent;
}
#drawerMenu .mega-menu {
  max-width: 400px;
}
#drawerMenu .mega-menu .product-list {
  display: flex;
  flex-direction: column;
  padding: 0;
  gap: 0;
}
#drawerMenu .mega-menu .product-list li {
  padding: 0;
}
#drawerMenu .mega-menu .product-list li a span {
  font-size: 14px;
}



