.cover {
    position: relative;
    width: 100%;
    height: 400px; /* Adjust height as needed */
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.cover:before {
    content: '';
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.6);
    z-index: 0;
}


.cover-content {
    color: white;
    text-align: center;
    padding: 20px;
    z-index: 1;
}

.cover h1 {
    font-size: 2.5em;
}

.cover p {
    font-size: 1.2em;
    color: rgba(255,255,255,.6);
}