#features {
  padding: 80px 0;
    .item {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #EEEEEE;
      border-radius: 15px;
      overflow: hidden;
      position: relative;
      transition: 500ms ease;
      a {
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
      }
      img {

        transition: 500ms ease;
        width: 100%;
        border-radius: 15px;
      }
        &:hover,
        &:hover {
          transition: 500ms ease;
          img {
            transform: scale(1.1);
            transition: 500ms ease;
            border-radius: 15px;
          }
        }
    }
}

// `sm` applies to x-small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {}

// `md` applies to small devices (landscape phones, less than 768px)
@media (min-width: 767.98px) {}

// `lg` applies to medium devices (tablets, less than 992px)
@media (min-width: 991.98px) {}

// `xl` applies to large devices (desktops, less than 1200px)
@media (min-width: 1199.98px) {}

// `xxl` applies to x-large devices (large desktops, less than 1400px)
@media (max-width: 1399.98px) {}