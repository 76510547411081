#slider {
    position: relative;

    .swiper {
        width: 100%;
        .swiper-slide {
            text-align: center;
            font-size: 18px;
            background: #fff;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                display: block;
                width: 100%;

                object-fit: fill;
            }

            .slide-content {
                position: absolute;
                bottom: 20px;
                left: 20px;
                background: rgba(0, 0, 0, 0.5);
                color: white;
                padding: 10px;
                border-radius: 5px;
                h2 {
                    margin: 0;
                    font-size: 1.5em;
                }
                p {
                    margin: 5px 0 0;
                    font-size: 1em;
                }
            }
        }

        .swiper-pagination-bullet {
            width: 25px;
            height: 2px;
            border-radius: 0;
            background: #cccccc;
            opacity: 1;
            &.swiper-pagination-bullet-active {
                background: #000;
            }
        }
    }

    /*.product-details {


        .swiper {
            width: 100%;
            height: 300px;
            margin-left: auto;
            margin-right: auto;
            .swiper-button-prev, .swiper-button-next {
                color: #1a1a1a !important;
            }
        }

        .swiper-slide {
            background-size: cover;
            background-position: center;
        }

        .mySwiper2 {
            height: 80%;
            width: 100%;
        }

        .mySwiper {
            height: 20%;
            box-sizing: border-box;
            padding: 10px 0;
        }

        .mySwiper .swiper-slide {
            width: 25%;
            height: 100%;
            opacity: 0.4;
        }

        .mySwiper .swiper-slide-thumb-active {
            opacity: 1;
        }

        .swiper-slide img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }




    }*/
}


// `sm` applies to x-small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
    #slider {
        .swiper {
            height: 375px;
        }
    }
}

// `md` applies to small devices (landscape phones, less than 768px)
@media (min-width: 767.98px) {
    #slider {
        .swiper {
            height: 475px;
        }
    }
}

// `lg` applies to medium devices (tablets, less than 992px)
@media (min-width: 991.98px) {
    #slider {
        .swiper {
            height: 540px;
        }
    }
}

// `xl` applies to large devices (desktops, less than 1200px)
@media (min-width: 1199.98px) {
    #slider {
        .swiper {
            height: 640px;
        }
    }
}

// `xxl` applies to x-large devices (large desktops, less than 1400px)
@media (max-width: 1399.98px) {}